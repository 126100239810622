<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 307.66">
    <path
      d="M239.68,40.71c-28.57-40.89-85-53-125.79-27L42.25,59.36A82.21,82.21,0,0,0,5.11,114.41,86.57,86.57,0,0,0,13.65,170a82.43,82.43,0,0,0-12.3,30.72,87.58,87.58,0,0,0,15,66.24c28.57,40.89,85,53,125.78,27l71.65-45.67a82.21,82.21,0,0,0,37.14-55.05,86.69,86.69,0,0,0-8.53-55.58A82.41,82.41,0,0,0,254.65,107a87.58,87.58,0,0,0-15-66.24"
      transform="translate(0 0)" />
    <path class="cls-1"
      d="M106.89,270.84a56.91,56.91,0,0,1-61.1-22.65,52.68,52.68,0,0,1-9-39.85,50.13,50.13,0,0,1,1.72-6.69l1.35-4.12,3.67,2.7a92.5,92.5,0,0,0,28,14l2.67.81L74,217.71a16.1,16.1,0,0,0,2.89,10.65,17.14,17.14,0,0,0,18.4,6.83,16,16,0,0,0,4.4-1.93l71.67-45.68a14.92,14.92,0,0,0,6.74-10,16,16,0,0,0-2.72-12,17.17,17.17,0,0,0-18.4-6.83,15.74,15.74,0,0,0-4.4,1.94L125.2,178.13a52.25,52.25,0,0,1-14.55,6.39,56.92,56.92,0,0,1-61.1-22.65,52.68,52.68,0,0,1-9-39.85A49.44,49.44,0,0,1,62.88,88.91l71.67-45.68a52.47,52.47,0,0,1,14.56-6.4,56.94,56.94,0,0,1,61.1,22.65,52.69,52.69,0,0,1,9,39.85A49.36,49.36,0,0,1,217.5,106l-1.35,4.12-3.67-2.69a92.3,92.3,0,0,0-28-14l-2.67-.8L182,90a16.12,16.12,0,0,0-2.89-10.66,17.16,17.16,0,0,0-18.4-6.83,15.74,15.74,0,0,0-4.4,1.94L84.67,120.09a14.87,14.87,0,0,0-6.73,10,15.89,15.89,0,0,0,2.71,12,17.13,17.13,0,0,0,18.4,6.83,16,16,0,0,0,4.4-1.93l27.35-17.43a52.28,52.28,0,0,1,14.55-6.4,56.94,56.94,0,0,1,61.1,22.65,52.64,52.64,0,0,1,9,39.85,49.43,49.43,0,0,1-22.33,33.12l-71.67,45.67a52.17,52.17,0,0,1-14.56,6.4"
      transform="translate(0 0)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoSvelte'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #fff;
  }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  }
  // {
  //   path: '/app',
  //   name: 'app',
  //   component: () => import('../views/app/AppView.vue'),
  //   meta: {
  //     autenticado: true
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {
//   let usuario = null
//   await createStore().then(store => {
//     usuario = store.getters.logeado
//   })
//   let autorizacion = to.matched.some(record => record.meta.autenticado)
//   if (autorizacion && !usuario) {
//     next('/')
//   } else if (!autorizacion && usuario) {
//     next ('/app')
//   } else {
//     next()
//   }
// })

export default router

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';
import '@/sass/overrides.sass'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00B1E6',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#007cf0',
        success: '#4CAF50',
        warning: '#FFC107',
        backcolor: '#E1E2E6',
        blackcolor: '#03091E'
      },
      dark: {
        info: '#33B1FF',
        accent: '#A3AFC2'
      }
    },
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'fa'
  }
})

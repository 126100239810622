<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 256 256">
    <defs>
      <mask id="mask" x="0" y="0" width="256" height="256" maskUnits="userSpaceOnUse">
        <g transform="translate(0 0)">
          <g id="mask-2">
            <circle id="path-1" class="cls-1" cx="128" cy="128" r="128" />
          </g>
        </g>
      </mask>
      <linearGradient id="Degradado_sin_nombre" x1="-167.34" y1="546.37" x2="-167.07" y2="545.97"
        gradientTransform="matrix(135.83, 0, 0, -157.65, 22863.68, 86314.2)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="Degradado_sin_nombre_2" x1="-158.6" y1="546.03" x2="-158.6" y2="545.29"
        gradientTransform="matrix(17.07, 0, 0, -102.4, 2878.82, 55990.11)" xlink:href="#Degradado_sin_nombre" />
    </defs>
    <g class="cls-2">
      <circle cx="128" cy="128" r="128" />
      <path class="cls-3" d="M212.63,224,98.34,76.8H76.8V179.16H94V98.68L199.11,234.45A129.22,129.22,0,0,0,212.63,224Z"
        transform="translate(0 0)" />
      <rect class="cls-4" x="163.56" y="76.8" width="17.07" height="102.4" />
    </g>
  </svg>
</template>
<script>
export default  {
  name: 'LogoNext'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    mask: url(#mask);
  }

  .cls-3 {
    fill: url(#Degradado_sin_nombre);
  }

  .cls-4 {
    fill: url(#Degradado_sin_nombre_2);
  }
</style>

<template>
  <v-card flat color="transparent" class="imgbkMenu">
    <v-card-text>
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <div class="container-id">
            <div class="line"></div>
            <div class="circle"><span>2</span></div>
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <span class="texto">Solución</span>
          <p class="mt-8 text-center display-2 font-weight-bold text--primary">con la mejor tecnología</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <patners-section></patners-section>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" class="text-center">
          <p class="subtitle-1 mb-1 text--primary font-weight-light">Elegir la herramienta adecuada para cada caso, ya sea un lenguaje de programación específico, una plataforma de desarrollo, una metodología de trabajo, etc.</p> 
          <p class="subtitle-1 mb-1 text--primary font-weight-light">Lo importante es que la tecnología sea adecuada para el proyecto y permita desarrollar un soluciones de calidad, eficientes y fáciles de mantener.</p> 
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PatnersSection from './PatnersSection.vue'
  
export default {
  name: 'SolutionSection',
  components: {
    PatnersSection
  }
}
</script>

<style lang="scss" scoped>
// .imgbkMenu {
//   background-image: url("/public/background/world_map.png");
//   // height: 100%;
//   // width: 100%;
//   // background-size: contain;
//   background-repeat: no-repeat;
//   background-color: white;
// }
.container-id {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1px;
  max-width: 100%;
  --flex: 1;
  --justify-content: flex-start;
  --align-items: stretch;
  flex: var(--flex);
  justify-content: var(--justify-content);
  align-items: var(--align-items);

}
.line {
  align-self: center;
  padding-right: 1px;
  width: 1px;
  height:100px;
  --start-color:#ffffff;
  --end-color:#7928ca;
  background: linear-gradient(var(--start-color),var(--end-color));
}
.circle {
  align-self: center;
  display: inline-flex;
  border-radius: 100%;
  color: #ffffff;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 24px;
  --start-color:#7928ca;
  --end-color:#ff0080;
  background: linear-gradient(90deg,var(--start-color),var(--end-color));
}
.texto {
  --start-color:#7928ca;
  --end-color:#ff0080;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg,var(--start-color),var(--end-color));
  font-size: 2rem;
  margin: 0;
  padding-top: 5px;
  font-weight: 800;
}
</style>
<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.4 351.11">
    <path id="path-1" class="cls-1" d="M1.29,279.43,2.9,276.3,102.25,87.78,58.1,4.31c-3.67-6.89-13-5.14-14.19,2.58Z"
      transform="translate(0 0.5)" />
    <path class="cls-2" d="M135,150.38l33-33.75L135,53.7a8.5,8.5,0,0,0-15,0L102.42,87.29v2.86Z"
      transform="translate(0 0.5)" />
    <path id="path-3" class="cls-1" d="M134.42,149l32-32.81-32-61c-3-5.79-10.44-6.39-13.45-.59l-17.7,34.11-.53,1.75Z"
      transform="translate(0 0.5)" />
    <polygon class="cls-3" points="0 283.5 0.96 282.53 4.46 281.11 132.94 153.11 134.56 148.68 102.51 87.61 0 283.5" />
    <path d="M139.12,347.55,255.4,282.7,222.19,78.21a8,8,0,0,0-13.47-4.34L0,283l115.61,64.55a24.13,24.13,0,0,0,23.51,0"
      transform="translate(0 0.5)" />
    <path d="M254.35,282.16,221.4,79.22c-1-6.35-7.56-9-12.1-4.43L1.29,282.6l114.34,63.91a23.94,23.94,0,0,0,23.33,0Z"
      transform="translate(0 0.5)" />
    <path
      d="M139.12,345.64a24.13,24.13,0,0,1-23.51,0L.93,282,0,283l115.61,64.55a24.13,24.13,0,0,0,23.51,0L255.4,282.7l-.29-1.75Z"
      transform="translate(0 0.5)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoFirebase'
}
</script>
<style lang="scss" scoped>
  .cls-1,
  .cls-2 {
    fill: #444;
  }

  .cls-1 {
    stroke: #444;
    stroke-miterlimit: 10;
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #4f4f4f;
  }
</style>

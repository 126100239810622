<template>
  <v-card flat color="transparent" class="pt-15">
    <v-card-text class="pb-0">
      <v-row class="justify-center align-center pb-0">
        <v-col cols="12"  md="auto" >
          <section class="section-process">
            <div class="section-container text-center">
              <div class="process-steps-container container-medium with-padding">
                <div class="process-step-container process-step-1">
                  <div class="process-step-title-container" :style="$vuetify.breakpoint.xsOnly ? '' : 'margin-top: -16px;'">
                    <h1 class="process-step-title black--text" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Diseño</h1>
                    <div class="process-step-title-overlay" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Diseño</div>
                  </div>
                </div>
                <div class="process-step-container process-step-2">
                  <div class="process-step-title-container" :style="$vuetify.breakpoint.xsOnly ? '' : 'margin-top: -16px;'">
                    <h1 class="process-step-title black--text" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Solución</h1>
                    <div class="process-step-title-overlay" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Solución</div>
                  </div>
                </div>
                <div class="process-step-container process-step-3">
                  <div class="process-step-title-container" :style="$vuetify.breakpoint.xsOnly ? '' : 'margin-top: -16px;'">
                    <h1 class="process-step-title black--text" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Innovación</h1>
                    <div class="process-step-title-overlay" :style="$vuetify.breakpoint.xsOnly ? 'font-size: 70px;' : 'font-size: 140px;'">Innovación</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </v-col>
      </v-row>
      <v-row class="justify-center pt-15">
        <v-col cols="10" md="6" lg="4" xl="3">
          <galaxy-btn text="Contactar Ahora"></galaxy-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center pt-10">
        <v-col cols="12" lg="10">
          <p class="text-center title font-weight-regular">Soluciones tecnológicas para satisfacer una amplia variedad necesidades, como mejorar la eficiencia de su empresa, facilitar el acceso a la información, optimizar los procesos de trabajo o mejorar la comunicación.</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import GalaxyBtn from '../tricks/GalaxyBtn.vue'

export default {
  name: 'HeroSection',
  components: {
    GalaxyBtn
  },
}
</script>
<style lang="scss" scoped>
.section-process {
  --gradient-color-1: #007cf0;
  --gradient-color-2: #00dfd8;
  --gradient-color-3: #7928ca;
  --gradient-color-4: #ff0080;
  --gradient-color-5: #ff4d4d;
  --gradient-color-6: #f9cb28;
  .process-steps-container {
    .process-step-container {
      .process-step-title-container {
        // margin-top: -16px; //To adjust for the line-height
        position: relative;
        .process-step-title,
        .process-step-title-overlay {
          
          font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          // font-size: 140px;
          font-weight: 900;
          margin: 0;
          line-height: 1.2em; //If too short, g might get cut off
          letter-spacing: -4px;
        }
        .process-step-title-overlay {
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translate(-50%, 0%);
          width: auto;
          // padding-right: 30px; //Adding some space on the right otherwise gradient may be cut off depending on the font.
          height: 100%;
          opacity: 1;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
      &.process-step-1 {
        .process-step-title-overlay {
          background-image: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2));
          animation: animated-gradient-title-1 8s infinite;
        }
        .process-step-title {
          animation: animated-gradient-title-1-inverted 8s infinite;
        }
      }

      &.process-step-2 {
        .process-step-title-overlay {
          background-image: linear-gradient(90deg, var(--gradient-color-3), var(--gradient-color-4));
          animation: animated-gradient-title-2 8s infinite;
        }
        .process-step-title {
          animation: animated-gradient-title-2-inverted 8s infinite;
        }
      }

      &.process-step-3 {
        .process-step-title-overlay {
          background-image: linear-gradient(90deg, var(--gradient-color-5), var(--gradient-color-6));
          animation: animated-gradient-title-3 8s infinite;
        }
        .process-step-title {
          animation: animated-gradient-title-3-inverted 8s infinite;
        }
      }

    }
  }
  @keyframes animated-gradient-title-1 {
    0%,
    16.667%,
    100% {
      opacity: 1
    }
    33.333%,
    83.333% {
      opacity: 0
    }
  }
  @keyframes animated-gradient-title-1-inverted {
    0%,
    16.667%,
    100% {
      opacity: 0
    }
    33.333%,
    83.333% {
      opacity: 1
    }
  }
  @keyframes animated-gradient-title-2 {
    0%,
    16.667%,
    66.667%,
    100% {
      opacity: 0
    }
    33.333%,
    50% {
      opacity: 1
    }
  }
  @keyframes animated-gradient-title-2-inverted {
    0%,
    16.667%,
    66.667%,
    100% {
      opacity: 1
    }
    33.333%,
    50% {
      opacity: 0
    }
  }
  @keyframes animated-gradient-title-3 {
    0%,
    50%,
    100% {
      opacity: 0
    }
    66.667%,
    83.333% {
      opacity: 1
    }
  }
  @keyframes animated-gradient-title-3-inverted {
    0%,
    50%,
    100% {
      opacity: 1
    }
    66.667%,
    83.333% {
      opacity: 0
    }
  }
}
</style>
  
<template>
  <v-app class="backcolor">
    <home-bar></home-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-fab-transition>
      <v-btn
        v-show="isFlat"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        class="mb-10"
        color="primary" 
        @click="goTop"
      >
        <v-icon small>
          fa-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <app-footer></app-footer>
  </v-app>
</template>
<script>
import HomeBar from './components/base/HomeBar.vue'
import AppFooter from './components/base/AppFooter.vue'
export default {
  name: 'App',
  components: {
    HomeBar,
    AppFooter
  },
  data: () => ({
    isFlat: false
  }),
  metaInfo () {
    return {
      title: 'KIREJ',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'es'
      },
      base: { target: '_blank', href: '/' },
      meta: [
        { charset: 'utf-8' },
        { vmid: 'description', name: 'description', content:  'KIREJ Soluciones Tecnológicas' },
        { name: 'viewport', content: 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' },
        { name: 'twitter:card', content: 'sumary' },
        { name: 'twitter:site', content: 'KIREJ' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'KIREJ' },
        { name: 'twitter:description', content: 'KIREJ Soluciones Tecnológicas' },
        // { name: 'twitter:image:src', content: '' },
        { property: 'fb:app_id', content: '1107988263128174' },
        { property: 'og:site_name', content: 'KIREJ' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'KIREJ' },
        { property: 'og:url', content: 'https://kirej.com' },
        { property: 'og:description', content: 'KIREJ Soluciones Tecnológicas' },
        // { property: 'og:image', content: ' },
        { property: 'og:image:alt', content: 'KIREJ Soluciones Tecnológicas' },
        { itemprop: 'name', content: 'KIREJ' },
        { itemprop: 'description', content: 'KIREJ Soluciones Tecnológicas' },
      ],
      link: [
        { rel: 'canonical', href: 'https://kirej.com' },
        { rel: 'icon', href: '/logo.png' },
        { rel: 'favicon', href: '/favicon.ico' },
        { rel: 'shortcut icon', type: 'image/png', href: '/logo.png' },
        { rel: 'apple-touch-icon', type: 'image/png', href: '/logo.png' },
        { hid: 'icon', rel: 'icon', type: 'image/x-icon', href: '/logo.png' },
        { hid: 'icon', rel: 'icon', type: 'image/icon', href: '/logo.png' },
        // { hid: 'manifest', rel: 'manifest', href: `data:application/manifest+json,${encodeURIComponent(JSON.stringify(pwa.manifest))}` },
        { rel: 'icon', type: 'image/x-icon', href: '/logo.png' }
      ]
    }
  },
  computed: {
    isHome () {
      return this.$route.name === 'app'
    },
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.isFlat = top > 150
    },
    goTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>
<style>
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4247.09 1376.02">
    <path class="cls-1"
      d="M345,1189.16c-133.45,0-258.18-129.08-149.15-312.25,102.05,89.84,205,53.2,311.39-133.45,55.82-99.44,98.56-205.85,143-287-143,3.49-239.86,74.14-159.62,130-28.78,271.26-389,205.84-280.85-60.19,68-164.85,307.89-170.95,488.44-182.29,9.6-14,28.79-33.14,44.49-33.14,14.82,0-1.75,32.27,4.36,32.27,67.16.87,168.34-9.6,218-2.62,58.44,8.72,59.31,97.69-6.1,100.31,8.72-31.4-200.61-3.49-240.74,5.23C657.27,602.16,618,863,564.81,993.79,503.76,1145.55,420.9,1189.16,345,1189.16Z"
      transform="translate(-38.57 -65.62)" />
    <path class="cls-1"
      d="M906.73,949.3c-24.43,63.67-82.86,122.11-145.66,122.11-68-8.72-109.9-67.16-116.88-132.57C649.42,821.09,762.81,632.69,850,627.45c36.64-2.61,87.22,12.21,116.88,35.76,35.76-62.8,130.83-34.89,117.75,40.13-35.76,32.27-85.48,217.18-61.93,229.39,17.45,8.72,63.67-85.48,81.12-122.11,13.08-24.42,75-6.11,61.93,32.27-27,75-114.26,228.52-176.19,228.52C912.83,1071.41,897.13,1021.7,906.73,949.3Zm23.55-261.66C860.5,628.32,773.28,908.31,816,920.52,867.48,935.35,947.72,702.46,930.28,687.64Z"
      transform="translate(-38.57 -65.62)" />
    <path class="cls-1"
      d="M1075.93,947.56c6.11-68,118.62-577.41,263.41-619.28,59.31-16.57,107.28,30.53,88.1,104.67-70.65-69.78-152.64,244.22-171,307.89,40.12-71.52,116-141.3,189.27-109,85.48,38.38,54.08,131.71,8.72,160.49-37.5-33.14-107.28-20.93-148.27,2.62,55,115.13,125.6,218.05,201.48,41.87,13.08-28.79,84.61-17.45,68.91,20.06-22.68,51.46-88.1,211.07-168.34,213.69-85.48,1.75-171-101.18-191-178.8-20.06,72.39-21.8,141.3,1.74,158.74C1135.24,1110.66,1066.34,1066.18,1075.93,947.56Z"
      transform="translate(-38.57 -65.62)" />
    <path class="cls-1"
      d="M1507.68,814.11c37.5-91.59,130-201.49,258.17-183.17,167.47,23.55,55,312.26-127.34,246.84-3.49,41.87,14.83,76.76,64.54,76.76,23.55,0,99.44-28.79,138.69-117.75,22.67-49.72,89.84-25.3,72.39,13.08-41,107.28-131.7,222.42-283.47,221.54C1496.34,1071.41,1458,935.35,1507.68,814.11ZM1767.6,683.27c-40.12-4.36-118.62,122.12-124.73,145.67C1690,853.36,1779.81,685,1767.6,683.27Z"
      transform="translate(-38.57 -65.62)" />
    <path class="cls-1"
      d="M2066.77,985.06c-29.66,48-76.76,84.61-127.35,84.61-80.24,0-119.49-58.44-116.88-132.58,5.24-117.75,126.48-306.15,213.7-311.38,27-1.75,67.16,13.08,95.07,26.17,41-147.41,102.05-301.79,178.81-323.6,58.43-17.44,109,29.66,92.45,104.67-98.56-94.2-216.31,492.8-177.93,498,27,4.36,74.14-79.38,94.2-120.37,13.08-24.42,79.37-7.85,65.41,29.66-41,107.28-116.87,226.77-198,234.62C2120.84,1081,2080.72,1038.27,2066.77,985.06ZM2113,689.38c-69.77-58.44-172.7,256.43-126.47,256.43C2041.47,946.69,2130.44,704.21,2113,689.38Z"
      transform="translate(-38.57 -65.62)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoTaked'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #37394f;
  }
</style>

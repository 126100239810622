import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
// import gsap from './plugins/gsap'
import VueMeta from 'vue-meta'

import '@fortawesome/fontawesome-free/css/all.css'
import './moment'
import './vee-validate'

Vue.use(VueMeta, {
  // optional pluginOptions
  // keyName: 'metaInfo',
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  // refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')  

<template>
  <v-container fluid fill-height>
    <v-row>
    <!-- <v-row :style="{background: gradient}"> -->
      <v-col cols="12">
        <hero-section></hero-section>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="subtitle-2 text-center font-weight-bold">CONOCE EL CAMINO A SEGUIR</p>
        <desing-section></desing-section>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="imgbkMenu">
          <v-row>
            <v-col cols="12">
              <info-section></info-section>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <innovation-section></innovation-section>
            </v-col>
          </v-row>
        </div>
      </v-col>  
    </v-row>
    <v-row>
      <v-col cols="12" class="py-10">
        <products-section></products-section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeroSection from '@/components/Home/HeroSection.vue'
import DesingSection from '@/components/Home/DesingSection.vue'
import InnovationSection from '@/components/Home/InnovationSection.vue'
import InfoSection from '@/components/Home/InfoSection.vue'
import ProductsSection from '@/components/Home/ProductsSection.vue'

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    DesingSection,
    InnovationSection,
    InfoSection,
    ProductsSection
  },
  data: () => ({
    colors: [
      { hex: '#F2F3F5' },
      { hex: '#F2F3F5' },
      { hex: '#FFFFFF' },
      { hex: '#FFFFFF 100%' }
    ]
  }),
  computed: {
    gradient () {
      let color = 'linear-gradient(to bottom'
      this.colors.forEach((e) => {
        color += ',' + e.hex
      })
      color += ')'
      return color
    },
  }
}
</script>
<style lang="scss" scoped>
.imgbkMenu {
  background-image: url("/public/background/world_map.png");
  background-repeat: no-repeat;
  background-color: white;
}
</style>

<template>
  <v-app-bar
    flat
    app
    color="#FAFAFA"
    height="100"
    max-height="100"
  >
    <v-row class="justify-space-between align-center">
      <v-col cols="5" md="3" lg="2">
        <router-link to="/">
          <v-avatar tile width="100%" :height="heightImg" class="pa-1">
            <logo-color />
          </v-avatar>
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-space-between align-center">
        <div>
          <a class="mx-2 f-resaltarlink" href="#taked" target="_self">
          <!-- <a class="mx-2 f-resaltarlink" href="https://taked.app" target="_blank"> -->
            TAKED
          </a>
          <a class="mx-2 f-resaltarlink" href="#kidocs" target="_self">
            KIDOCS
          </a>
          <a class="mx-2 f-resaltarlink" href="#kifix" target="_self">
            KIFIX
          </a>
        </div>
        <div>
          <v-btn
            dark
            depressed
            small
            to="/contact"
          >
            <!-- target="_blank" -->
            <!-- href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*KIREJ*.%20Mi%20nombre%20es%20" -->
            Contactar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import LogoColor from '../Home/logos/LogoColor.vue'
export default {
  name: 'HomeBar',
  components: {
    LogoColor
  },
  computed: {
    isHome () {
      return this.$route.name === 'home'
    },
    heightImg () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
            return '90'
        case 'lg':
          return '90'
        case 'md':
          return '90'
        case 'sm':
          return '70'
        case 'xs':
          return '60'
        default:
          return '90'
      }
    }
  }
}
</script>
<style>
</style>
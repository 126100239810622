<template>
  <v-card flat color="transparent">
    <v-card-text>
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <text-rotate
            texto="Soluciones Tecnológicas"
            :palabras="['idóneas', 'modernas', 'efectivas', 'funcionales', 'proactivas', 'profesionales', 'continuas', 'flexibles', 'simples', 'reales']"
            color="primary--text"
          ></text-rotate>
        </v-col>
      </v-row>
      <v-row name="taked" id="taked">
        <v-col cols="12" md="6">
          <div>
            <v-img
              class="imgApp"
              src="/cards/apps/taked.jpg"
              contain
              width="100%"
              height="auto"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pt-1">
          <div>
            <v-avatar tile height="100" width="auto">
              <logo-taked></logo-taked>
            </v-avatar>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <p class="mb-1 title info--text">Gestión Online del Servicio de Restaurante</p>
            <p class="subtitle-2 text--primary font-weight-regular">Pontente herramienta para la gestión del servicio de restaurante, basada en tecnologías flexibles para que se adapten a todo tipo y tamaño de negocio, con interfaces modernas, amigables y sencillas. On-line, no requiere instalación, accesible en todo momento y en todo lugar desde cualquier dispositivo.</p>
            <p class="mb-1 font-weight-light text--primary">
              <v-icon color="info" left x-small class="mt-n1">
                fa-circle
              </v-icon>
              <span>Crea órdens y emite comandas en segundos.</span>
            </p>
            <p class="mb-1 font-weight-light text--primary">
              <v-icon color="info" left x-small class="mt-n1">
                fa-circle
              </v-icon>
              <span>Control total de ventas e inventarios.</span>
            </p>
            <p class="mb-1 font-weight-light text--primary">
              <v-icon color="info" left x-small class="mt-n1">
                fa-circle
              </v-icon>
              <span>Menú digital y acceso QR.</span>
            </p>
            <p class="mb-1 font-weight-light text--primary">
              <v-icon color="info" left x-small class="mt-n1">
                fa-circle
              </v-icon>
              <span>Digitaliza la cocina y el bar.</span>
            </p>
            <div class="mt-8">
              <v-btn
                depressed
                color="info"
                large
                href="https://taked.app"
              >
                SABER MÁS
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TextRotate from '@/components/tricks/TextRotate.vue'
import LogoTaked from './logos/LogoTaked.vue'


export default {
  name: 'ProductsSection',
  components: {
    LogoTaked,
    TextRotate
  }
}
</script>
<style lang="scss" scoped>
.imgApp {
  border-radius: 12px !important;
  border: 3px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  transform:
    perspective(1000px)
    rotateY(25deg) scale(0.9)
    rotateX(10deg);
  transition: 0.6s ease all;

  &:hover {
    transform:
      perspective(800px)
      rotateY(0deg)
      translateY(-10px)
      rotateX(0deg)
      scale(1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 3px 5px 5px 3px rgba(0, 0, 0, 0.2);
  }
}
</style>

<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 171.41">
    <path class="cls-1"
      d="M113,9.25a18.7,18.7,0,0,0-32.27,0L2.52,143.66a18.5,18.5,0,0,0,16.14,27.75h61a17.87,17.87,0,0,1-3.76-22.58l59.2-101.48Z"
      transform="translate(0 0)" />
    <path
      d="M162.5,38.73a15.58,15.58,0,0,1,26.72,0l64.69,110c5.94,10.1-1.48,22.71-13.36,22.71H111.17c-11.87,0-19.3-12.61-13.36-22.71Z"
      transform="translate(0 0)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoNuxt'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #444;
  }
</style>

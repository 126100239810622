<template>
  <div class="caja">
      <p>{{ texto }}</p>
      <ul class="lista">
        <li
          v-for="(palabra, i) in palabras"
          :key="i"
          :class="color"
        >{{ palabra }}</li>
      </ul>
    </div>
</template>

<script>
export default {
  name:'TextRotate',
  props: {
    texto: {
      type: String,
      require: true
    },
    palabras: {
      type: Array,
      require: true
    },
    color: {
      type: String,
      require: false,
      default: 'text--primary'
    }
  }
}
</script>
<style lang="scss" scoped>
  .caja {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 30px;
    justify-content: center;
    line-height: 30px;
    margin: 0 auto;
    overflow: hidden;
  }
  .lista {
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: Text_change;
    animation-name: Text_change;
    list-style: none;
    margin: 0;
    padding: 0 0 0 9px;
    text-align: left;
    li {
      // color: red;
      line-height: 30px;
      margin: 0;
    }
  }
  @keyframes Text_change {
    0%, 8% {
      opacity: 1;
      transform: translateZ(0);
    }
    10%, 18% {
      transform: translate3d(0,-100%,0);
    }
    20%, 28% {
      transform: translate3d(0,-200%,0);
    }
    30%, 38% {
      transform: translate3d(0,-300%,0);
    }
    40%, 48% {
      transform: translate3d(0,-400%,0);
    }
    50%, 58% {
      transform: translate3d(0,-500%,0);
    }

    60%, 68% {
      transform: translate3d(0,-600%,0);
    }
    70%, 78% {
      transform: translate3d(0,-700%,0);
    }
    80%, 88% {
      transform: translate3d(0,-800%,0);
    }
    90%, 98% {
      transform: translate3d(0,-900%,0);
    }
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
</style>

<template>
  <v-card flat color="transparent">
    <v-card-text class="text--primary">
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <div class="container-id">
            <div class="line"></div>
            <div class="circle"><span>1</span></div>
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <span class="texto">Diseño</span>
          <p class="mt-8 text-center display-2 font-weight-bold text--primary">basado en necesidades y recursos</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p></p>
        </v-col>
        <v-col cols="6"></v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" md="6" lg="5" xl="4">
          <v-row class="justify-center">
            <v-col cols="12">
              <p class="display-1 font-weight-black">¿Qué necesitas para <span class="primary--text">crecer</span>?</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <p class="text-start subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Conocer y gestinar tus recursos de forma integral y automatizada.</span>
              </p>
              <p class="text-start subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Comercializar tus productos y servicios por medios digitales.</span>
              </p>
              <p class="text-start subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Gestionar a tus clientes, brindarles mejor atención y aumentar tus ventas.</span>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p class="subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Seguridad en tu información y protección de tus datos.</span>
              </p>
              <p class="subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Presencia digital, sin fronteras en los mercados globales.</span>
              </p>
              <p class="subtitle-1 font-weight-regular">
                <v-icon color="primary" left small>
                  fa-circle
                </v-icon>
                <span>Aliados estratégicos, para que te concentres en tu negocio.</span>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="4" class="text-center">
          <v-sheet color="transparent">
            <v-avatar tile height="460" width="100%" class="rounded-xl">
              <v-img
                src="/cards/offer/pc.png"
                contain
              >
              </v-img>
            </v-avatar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DesingSection'
}
</script>

<style lang="scss" scoped>
.container-id {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1px;
  max-width: 100%;
  --flex: 1;
  --justify-content: flex-start;
  --align-items: stretch;
  flex: var(--flex);
  justify-content: var(--justify-content);
  align-items: var(--align-items);

}
.line {
  align-self: center;
  padding-right: 1px;
  width: 1px;
  height:100px;
  --start-color:#ffffff;
  --end-color:#007cf0;
  background: linear-gradient(var(--start-color),var(--end-color));
}
.circle {
  align-self: center;
  display: inline-flex;
  border-radius: 100%;
  color: #ffffff;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 24px;
  --start-color:#007cf0;
  --end-color:#00dfd8;
  background: linear-gradient(90deg,var(--start-color),var(--end-color));
}
.texto {
  --start-color: #007CF0;
  --end-color: #00DFD8;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg,var(--start-color),var(--end-color));
  font-size: 2rem;
  margin: 0;
  padding-top: 5px;
  font-weight: 800;
}
</style>
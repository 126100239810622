<template>
  <v-footer
    absolute
    app
    dark
    >
    <v-container fluid>
      <!-- <v-row dense class="justify-center">
        <v-col
          v-for="(info, i) in info"
          :key="i"
          cols="auto"
        >
          <router-link
            class="caption f-resaltarlink font-weight-bold mx-2 grey--text"
            :to="info.link"
          >
            {{ info.nombre }}
          </router-link>
        </v-col>
      </v-row> -->
      <v-row dense class="justify-center mt-2 grey--text">
        <v-col
          class="text-center"
          cols="auto"
        >
          <router-link class="d-flex caption grey--text align-center" style="text-decoration:none" to="/">
            <div>
              <v-avatar
                tile
                size="58"
                class="mt-n4"
                style="cursor: pointer;"
              >
                <!-- <logo /> -->
                <v-img
                  src="IconSF.png"
                ></v-img>
              </v-avatar>
              <span class="ml-n5">Kirej</span>
            </div>
            <div class="ml-1">
              • Copyright &copy; {{ (new Date()).getFullYear() }}
            </div>
          </router-link>
          <div class="subtitle-2 font-weight-light">
            Soluciones Tecnológicas
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="justify-center mt-1">
        <v-col cols="auto">
          <v-btn
            class="grey--text"
            text
            target="_blank"
            href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*KIREJ*.%20Mi%20nombre%20es%20"
            style="text-transform:none"
          >
            <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
            0987-679-471
          </v-btn>
          <v-btn
            class="grey--text"
            text
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5930984674974&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*KIREJ*.%20Mi%20nombre%20es%20"
            style="text-transform:none"
          >
            <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
            0984-674-974
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
// import Logo from '../Home/logos/LogoIcon.vue'
export default {
  name: 'FooterSite',
  // components: {
  //   Logo
  // },
  data: () => ({
    info: [
      {
        nombre: 'Quiero colaborar',
        link: '/colaborate'
      },
      {
        nombre: 'Políticas',
        link: '/polices'
      },
      {
        nombre: 'Contactarme',
        link: '/contact'
      }
    ]
  })
}
</script>

<style lang="scss">
.f-resaltarlink {
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.f-resaltarlink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #00B1E6;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition-delay: 50ms;
  width: 0;
}
.f-resaltarlink:hover:after {
  transition-delay: 0.2s;
  width: 100%;
  left: 0%;
}
</style>

<template>
  <v-card flat color="transparent" class="mb-10">
    <v-card-text class="text--primary">
      <v-row class="justify-center align-center">
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="35">
            <logo />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-vue />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-react />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="30">
            <logo-firebase />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-nuxt />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-svelte />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-angular />
          </v-avatar>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-avatar tile width="100%" height="25">
            <logo-next />
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-text>
    <div class="top-line"></div>
  </v-card>
</template>

<script>
import logo from './logos/LogoBN.vue'
import LogoVue from './logos/LogoVue.vue'
import LogoReact from './logos/LogoReact.vue'
import LogoFirebase from './logos/LogoFirebase.vue'
import LogoNuxt from './logos/LogoNuxt.vue'
import LogoSvelte from './logos/LogoSvelte.vue'
import LogoAngular from './logos/LogoAngular.vue'
import LogoNext from './logos/LogoNext.vue'

export default {
  name: 'PatnersSection',
  components: {
    logo,
    LogoVue,
    LogoReact,
    LogoFirebase,
    LogoNuxt,
    LogoSvelte,
    LogoAngular,
    LogoNext
  },
  data: () => ({
    patners: [
      {
        id: 0,
        name: '',
        image: '/logo.png'
      }
    ]
  })
}
</script>
<style lang="scss" scoped>
.top-line{
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100px;
  height: 2px;
  background: #ff0080;
  clip-path: poligon(0% 0%);
  transition: 0.6s ease-in-out;
}
</style>

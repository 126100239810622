<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 270.44">
    <path class="cls-1" d="M.1,45.52,125.91.7l129.19,44L234.19,211.18l-108.28,60L19.32,212Z"
      transform="translate(-0.1 -0.7)" />
    <path d="M255.1,44.73,125.91.7V271.14l108.28-59.87L255.1,44.73Z" transform="translate(-0.1 -0.7)" />
    <path class="cls-2"
      d="M126.11,32.27h0L47.71,206.69l29.29-.5,15.74-39.34h70.32l17.24,39.84,28,.5L126.11,32.27Zm.2,55.89,26.49,55.38H103l23.31-55.38Z"
      transform="translate(-0.1 -0.7)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoAngular'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #444;
  }

  .cls-2 {
    fill: #fff;
  }
</style>

<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 220.8">
    <path class="cls-1" d="M204.8,0H256L128,220.8,0,0H97.92L128,51.2,157.44,0Z" transform="translate(0 0)" />
    <path d="M0,0,128,220.8,256,0H204.8L128,132.48,50.56,0Z" transform="translate(0 0)" />
    <path class="cls-2" d="M50.56,0,128,133.12,204.8,0H157.44L128,51.2,97.92,0Z" transform="translate(0 0)" />
  </svg>
</template>
<script>
export default  {
  name: 'LogoVue'
}
</script>
<style lang="scss" scoped>
  .cls-1 {
    fill: #444444;
  }

  .cls-2 {
    fill: #444;
  }
</style>

<template>
  <a
    target="_blank"
    href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*KIREJ*.%20Mi%20nombre%20es%20"
    class="button text-center"
    width="500"
  >
    <span class="font-weight-bold subtitle-1">{{ text }}</span>
  </a>
</template>

<script>
export default {
  name: 'GalaxyBtn',
  props: {
    text: {
      type: String,
      require: false,
      default: 'INICIAR AHORA'
    }
  }
}
</script>
<style lang="scss" scoped>
.button{
  display: inline-block;
  text-decoration: none;
  color: #000000;
  padding: 1px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.button::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(115deg, #007cf0, #00dfd8,#7928ca, #ff0080, #ff4d4d, #f9cb28);
  background-size: 50% 100%;
  border-radius: inherit;
}

.button:hover::before{
  animation: animate_border 0.6s linear infinite;
}

.button span{
  display: block;
  background: #FFFFFF;
  // background: #03091E;
  backdrop-filter:blur(115px) saturate(50%);
  padding: 13px 20px;
  border-radius: 3px;
  position: relative;
  z-index: 2;
}

@keyframes animate_border{
  to {
    transform: translateX(-50%)
  }
}
</style>
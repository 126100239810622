<template>
  <v-card flat color="transparent" class="imgBack">
    <v-card-text>
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <div class="container-id">
            <div class="line"></div>
            <div class="circle"><span>3</span></div>
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <span class="texto">Innovación</span>
          <p class="mt-8 text-center display-2 font-weight-bold text--primary">para que todo sea mejor</p>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col
          v-for="item in cardItems"
          :key="item.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-hover
            v-slot="{ hover }"
            close-delay="200"
          >
            <v-card outlined :color="hover ? 'white' : '#F8F8F8'" class="rounded-xl py-8 px-2">
              <v-card-title class="d-flex align-center justify-center">
                <v-avatar :color="hover ? '#F8F8F8':'white'" size="56" tile class="rounded">
                  <v-icon :color="item.colorIcono">{{ item.icono }}</v-icon>
                </v-avatar>
              </v-card-title>
              <v-card-text class="text-center" :class="hover ? 'text--primary':''">
                <v-row>
                  <v-col>
                    <p class="mb-2 text--primary font-weight-bold subtitle-1 toguether-text">{{ item.titulo }}</p>
                  </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row>
                  <v-col>
                    <p>
                      {{ item.texto }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InnovationSection',
  data: () => ({
    cardSelected: 0,
    cardItems: [
      {
        id: 1,
        titulo: 'Pensamiento creativo',
        subtitulo: 'Subtitulo del card',
        texto: 'Trabajamos en desarrollar habilidades y actitudes para generar nuevas ideas y soluciones a problemas.',
        icono: 'fa-rocket',
        colorIcono: '#FF514C'
      },
      {
        id: 2,
        titulo: 'Colaboración',
        subtitulo: 'Subtitulo del card',
        texto: 'Promovemos el trabajar en equipo para compartir ideas y conocimientos, y así lograr un resultado más completo y sólido.',
        icono: 'fa-people-group',
        colorIcono: '#FD7441'
      },
      {
        id: 3,
        titulo: 'Aprendizaje continuo',
        subtitulo: 'Subtitulo del card',
        texto: 'Estamos siempre abierto a nuevos conocimientos y habilidades, estar dispuesto a adaptarnos y cambiar en función de las necesidades del mercado y del entorno.',
        icono: 'fa-brands fa-leanpub',
        colorIcono: '#FB9039'
      },
      {
        id: 4,
        titulo: 'Innovación abierta',
        subtitulo: 'Subtitulo del card',
        texto: 'Buscamos inspiración y colaboración en diferentes fuentes, ya sean internas o externas, estar abiertos a nuevas formas de trabajo y de resolución de problemas.',
        icono: 'fa-seedling',
        colorIcono: '#FABA2D'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>

.container-id {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1px;
  max-width: 100%;
  --flex: 1;
  --justify-content: flex-start;
  --align-items: stretch;
  flex: var(--flex);
  justify-content: var(--justify-content);
  align-items: var(--align-items);

}
.line {
  align-self: center;
  padding-right: 1px;
  width: 1px;
  height:100px;
  --start-color:#ffffff;
  --end-color:#ff4d4d;
  background: linear-gradient(var(--start-color),var(--end-color));
}
.circle {
  align-self: center;
  display: inline-flex;
  border-radius: 100%;
  color: #ffffff;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 24px;
  --start-color:#ff4d4d;
  --end-color:#f9cb28;
  background: linear-gradient(90deg,var(--start-color),var(--end-color));
}
.texto {
  --start-color:#ff4d4d;
  --end-color:#f9cb28;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg,var(--start-color),var(--end-color));
  font-size: 2rem;
  margin: 0;
  padding-top: 5px;
  font-weight: 800;
}
</style>